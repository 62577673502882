import { inject } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../service/auth.service';

export const authenticationGuard = (state: RouterStateSnapshot) => {
      const router = inject(Router);
      let lastUrl = state.url;
      if (lastUrl.includes('dashboard')) {
            lastUrl = "";
      }
      return inject(AuthService).LoggedUserValue?.Token ? true : router.navigate(['/authentication/signin'], { queryParams: { state: lastUrl } })
};

export const roleGuard = () => {
      const router = inject(Router);
      return inject(AuthService).LoggedUserValue.UserType === 1 ? true : router.navigate(['/403'])
};